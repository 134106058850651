import React from 'react'
import { Link } from 'gatsby'
import styled from "@emotion/styled"
import { HandWaving } from "phosphor-react"

const Wrap = styled.div`
margin-top: 200px;
background: rgba(0,0,0,0.8);
padding: 2.5vh 0;
a {
    color: #fff;
    display: block;
    border-bottom: 0;
    transition: all 0.3s ease;
    &:hover {
        color: #f15a24;
        svg {
            fill: #f15a24;
        }
    }
    svg {
        display: inline-block;
        vertical-align: top;
    }
}
`

const Intouch = () => {
    return (
        <Wrap>
            <Link to="/contact">Get in Touch / Say Hi <HandWaving size={24} color="#ffffff" /></Link>
        </Wrap>
    )
}

export default Intouch