module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"newq","accessToken":"MC5ZbXJ5UnhjQUFHZlpUUU1B.G--_ve-_vUAT77-977-977-977-9NO-_vW_vv73vv73vv73vv701R0_vv73vv71nKe-_ve-_ve-_ve-_ve-_vWcu77-977-9","promptForAccessToken":true,"apiEndpoint":"https://newq.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"qcollective.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Q Collective","short_name":"Q Collective","start_url":"/","background_color":"#ffffff","theme_color":"#efefef","display":"standalone","icon":"src/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"81a481324e42d41c224c158fcffcf9fa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
