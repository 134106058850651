import React from "react"
import styled from "@emotion/styled"

const Topwrap = styled.header`
background-color: rgba(0,0,0,0.07);
padding: 140px 0px 80px 0px;
h3 {
  font-family: 'Cormorant Garamond', serif;
  font-size: 14px;
  line-height: 1em;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: .5px;
  color: rgba(0,0,0,1);
  margin: 0px auto;
  padding-left: 2px;
  width: 79.5vw;
  &::after {
    content: ' //';
  }
}
h1 {
  font-family: "Freight Big Black Regular";
  font-size: 4.5rem;
  line-height: 1em;
  text-align: center;
  margin: 0px auto;
  width: 80vw;
  @media screen and (max-width: 767px) {
    font-size: 3rem;
  }
}
`

const Topsection = ({children}) => {
    return (
        <Topwrap>
            {children}
        </Topwrap>
    )
}

export default Topsection