import React, { useState } from 'react'
import { Link } from 'gatsby'
import { motion } from "framer-motion"

import styled from "@emotion/styled"

const Nav = styled.nav`
button {
    border: 0;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    color: white;
    font-size: 2.1rem;
    line-height: 68px;
    text-align: center;
    position: fixed;
    left: 50%;
    margin-top: 32px;
    padding: 0;
    transform: translateX(-50%);
    z-index: 100;
    height: 60px;
    width: 60px;
    background-color: black;
    transition: all 0.15s ease;
    span {
        visibility: hidden;
        font-size: 0;
    }
    &:hover {
        cursor: pointer;
        background-color: #f15a24;
        transform: translateX(-50%) scale(1.05);
        transform-origin: center;
        margin-top: 30px;
    }
    &[data-isopen='true'] + ul {
        position: fixed;
        transition: bottom 0.3s ease 0.3s;
        visibility: visible;
    }
  }
> ul {
    box-sizing: border-box;
    width: 100vw;
    background-color: rgba(75,75,75, 0.95);
    padding: 165px 10vw 0 10vw;
    z-index: 99;
    position: fixed;
    height: 60vh;
    visibility: hidden;
    left: 0;
    opacity: 0;
    top: 0;
    transition: visibility 0 linear;
    transition-delay: .3s;
    transition-property: visibility;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    > li {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 2.5rem;
        line-height: 1.33em;
        text-align: center;
        > ul {
            width: inherit;
            height: inherit;
            display: block;
            position: static;
            padding: 0;
            background: transparent;
        }
        @media (max-width: 676px) {
            margin-bottom: 30px;
            font-size: 2.25rem;
        }
    }
    a {
        color: white;
        transition: all 0.3s ease;
        &:hover {
            color: #f15a24;
        }
    }
    @media (max-width: 676px) {
        display: block;
        height: 90vh;
    }
}
`

const Mainnav = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleSwitch = () => setIsOpen(!isOpen);

    return (
        <Nav>
            <button onClick={toggleSwitch} data-isopen={isOpen}>Q<span> Collective</span></button>
            <motion.ul
            animate={{
                y: isOpen ? "0vh" : "-10vh",
                opacity: isOpen ? 1 : 0
            }}
            transition={{ duration: .5 }}
            >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <ul>
                  <li><Link to="/section/projects">Projects</Link></li>
                  <li><Link to="/section/ideas">Ideas</Link></li>
                  <li><Link to="/section/assignments">Assignments</Link></li>
                  <li><Link to="/section/writing">Writing</Link></li>
              </ul>
            </li>
            <li>
            <ul>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/clients">Clients</Link></li>
              </ul>
            </li>
            <li>
            <Link to="/contact">Contact</Link>
            </li>
            </motion.ul>
        </Nav>
    )
}

export default Mainnav