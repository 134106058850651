import React from 'react'
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import { PrismicRichText } from '@prismicio/react'
import { linkResolver } from '../utils/linkResolver'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '../components/layout'
import Topsection from '../components/topsection'
import Seo from '../components/seo'

const Main = styled.main`
width: 60vw;
margin: 0px auto;
padding-top: 10vh;
text-align: left;
@media screen and (max-width: 767px) {
  width: auto;
  margin: 0px 20px;
}
h3 {
  font-family: "Freight Big Black Regular";
  font-size: 3rem;
  line-height: 1em;
  margin-bottom: 26px;
  &::before {
    content: '';
    display: block;
    width: 15%;
    margin: 40px auto 60px 0px;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.3);
  }
  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
}
h6 {
    font-size: .8125rem;
    padding-top: 13px;
    font-weight: 700;
}
p {
  padding-bottom: 26px;
  color: rgba(0,0,0,.8);
  a {
    border-bottom: solid 3px rgba(0,0,0,0.1);
    font-weight: 450;
    padding-bottom: 3px;
    &:hover {
      color: rgba(0,0,0,1);
      border-bottom-color: rgba(255,222,23,1);
    }
  }
  strong {
    font-weight: 800;
  }
  em {
    border-bottom: solid 1px rgba(0,0,0,0.25);
    font-variation-settings: 'slnt' -8;
  }
}
pre {
  border-left: solid 1px rgba(0,0,0,0.2);
  padding-left: 40px;
  padding-bottom: 26px;
  margin-bottom: 26px;
  color: rgba(0,0,0,.8);
}
`

const Pages = ({data}) => {
    if (!data) return null
    const doc = data.prismicPerson.data

    return (
        <Layout>
          <Seo title={doc.title.text} />
            <Topsection>
                <PrismicRichText field={doc.title.richText} />
            </Topsection>
            <Main>
            <PrismicRichText field={doc.text.richText} linkResolver={linkResolver} />
            </Main>
        </Layout>
    )
}

export default withPrismicPreview(Pages)

export const pageQuery = graphql`
query ($id: String) {
    prismicPerson(id: {eq: $id}) {
      data {
        title {
          richText
          text
        }
        text {
          richText
        }
      }
    }
}
`