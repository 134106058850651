import React from "react"
import { motion } from "framer-motion"

import Mainnav from './mainnav'
import Intouch from './intouch'

const Layout = ({children}) => {
    return (
        <>
        <Mainnav />
        <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        duration: 0.5,
                        delay: 0.3
                      }}
            >

        {children}

        </motion.div>
        <Intouch />
        </>
    )
}

export default Layout